import React from "react"
import { Link } from "gatsby"

import logoWhite from "../../images/logo-white.svg"

const footer = props => {
  return (
    <div>
      <div class="container-fluid sub-footer">
        <div class="container light-clr sub-footer-inner">
          <div class="row">
            <div class="col-md-4">
              <h4 class="mt-0 mb-2">Quick Links</h4>
              <div class="row">
                <div class="col col-md-6">
                  <a href="">
                    <i class="fas fa-angle-right q-links"></i> Home
                  </a>
                  <a href="">
                    <i class="fas fa-angle-right q-links"></i> About Us
                  </a>
                  <a href="">
                    <i class="fas fa-angle-right q-links"></i> Services
                  </a>
                </div>
                <div class="col col-md-6">
                  <a href="">
                    <i class="fas fa-angle-right q-links"></i> Products
                  </a>
                  <a href="">
                    <i class="fas fa-angle-right q-links"></i> Reviews
                  </a>
                  <a href="">
                    <i class="fas fa-angle-right q-links"></i> Contact Us
                  </a>
                </div>
              </div>
              <h4 class="mt-3 mb-2">Working Hours</h4>
              <div class="row">
                <div class="col col-md-6 col-sm-2 light-clr">
                  <p class="mb-0 mt-0">Mon</p>
                  <p class="mb-0 mt-0">Tue - Sat</p>
                  <p class="mb-0 mt-0">Sunday</p>
                </div>
                <div class="col col-md-6 col-sm-10">
                  <p class="mb-0 mt-0">Closed</p>
                  <p class="mb-0 mt-0">10:00 am - 7:00 pm</p>
                  <p class="mb-0 mt-0">12:00 pm - 5:00pm</p>
                </div>
              </div>
            </div>
            <div class="col-md-4 subfooter-details">
              <img class="subfooter-logo" src={logoWhite} />
              <p class="address mb-1 mt-3">
                9475 Briar Village Point #158, Colorado Springs, CO 80920,
                United States
              </p>
              <a class="tel-num mt-0 mb-4">+1 719-375-0869</a>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-3">
              <h4 class="mt-0 mb-3">Follow Us</h4>
              <div class="row">
                <div class="col col-md-2 col-sm-2">
                  <a href="#" title="Facebook">
                    <i class="fab fa-facebook-f social-links"></i>
                  </a>
                </div>
                <div class="col col-md-2 col-sm-2">
                  <a href="#" title="Instagram">
                    <i class="fab fa-instagram social-links"></i>
                  </a>
                </div>
                <div class="col col-md-2 col-sm-2">
                  <a href="#" title="Twitter">
                    <i class="fab fa-twitter social-links"></i>
                  </a>
                </div>
                <div class="col col-md-2 col-sm-2"></div>
              </div>
              <p class="mt-4 mb-3 mb-4">
                Eu vel noster scaevola molestiae. Lorem ipsum dolor sit amet,
                nec cu omnium ponderum instructior, eligendi gubergren
                cotidieque te eam.
              </p>
              <a href="#" class="backtotop" title="Back to Top">
                <i class="fas fa-arrow-up"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <span>
            <sup>©</sup> Spruce and Polish 2020 All rights reserved. Designed
            and powered by {``}
            <a
              href="https://webriq.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              WebriQ
            </a>
            .
          </span>
        </div>
      </div>
    </div>
  )
}

export default footer
