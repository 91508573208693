import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import "./menu.css"
import logo from "../../images/logo.svg"

export default () => (
  <div className="navigation">
    <img className="mb-4" src={logo} />
    <Nav className="flex-column">
      <Nav.Item>
        <Link to="/">Home</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/blog">About Us</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/about-us">Services</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/">Products</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/blog">Reviews</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/about-us">Contact Us</Link>
      </Nav.Item>
    </Nav>
  </div>
)
